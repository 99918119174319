<template>
    <div class="page recordBox">
        <el-row :gutter="10">
            <el-col :span="showList ? 4 : 1" :class="['p_r', showList ? '' : 'el-col-unfold']">
                <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']" @click="showList = !showList"></div>
                <div class="bg-white" v-show="showList">
                    <el-descriptions title="档案分类"></el-descriptions>
                    <el-tree class="treeData" :data="treeData" :props="defaultProps" v-loading="treeLoading"
                             ref="myTree" node-key="id" highlight-current
                             @node-click="selRecord">
                        <div slot-scope="{ node, data }" class="omit">
                            <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                                <div>{{ node.label }}</div>
                            </el-tooltip>
                        </div>
                    </el-tree>
                </div>
            </el-col>
            <el-col :span="showList ? 20 : 24" :class="[showList ? '' : 'el-col-unfold']">
                <div class="tableForm">
                    <div class="bg-white m_b1">
                        <el-form size="small" class="query-form dynamicForm" ref="inputForm" :model="inputForm"
                                 label-width="120px" @keyup.enter.native="getList(1)">
                            <el-form-item prop="keyword" label="输入搜索：">
                                <el-input v-model.trim="inputForm.keyword" maxlength="50"
                                          placeholder="关键字搜索"
                                          clearable></el-input>
                            </el-form-item>
                            <el-form-item prop="archivesBasicDataType" label="档案类型：">
                                <el-select class="w100i" v-model="inputForm.archivesBasicDataType" clearable>
                                    <el-option
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            v-for="item in $dictUtils.getDictList('archivesBasicDataType')">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item prop="archivesDataName" label="题名：">
                                <el-input v-model.trim="inputForm.archivesDataName" maxlength="50" placeholder="请输入题名"
                                          clearable></el-input>
                            </el-form-item>
                            <el-form-item prop="warehousingState" label="状态：">
                                <el-select class="w100i" v-model="inputForm.warehousingState" clearable>
                                    <el-option
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            v-for="item in $dictUtils.getDictList('warehousing_state')">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item prop="quanzongNumber" label="全宗号：">
                                <el-input v-model.trim="inputForm.quanzongNumber" maxlength="50" placeholder="请输入全宗号"
                                          clearable></el-input>
                            </el-form-item>
                            <el-form-item prop="quanzongName" label="全宗名称：">
                                <el-input v-model.trim="inputForm.quanzongName" maxlength="50" placeholder="请输入全宗名称"
                                          clearable></el-input>
                            </el-form-item>
                            <el-form-item prop="reference" label="档号：">
                                <el-input v-model.trim="inputForm.reference" maxlength="50" placeholder="请输入档号"
                                          clearable></el-input>
                            </el-form-item>
                            <el-form-item prop="createBy" label="创建人：">
                                <el-select v-model="inputForm.createBy" filterable placeholder="请选择" clearable
                                           class="w100i">
                                    <el-option v-for="item in userList" :key="item.id" :label="item.name"
                                               :value="item.name"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item prop="createDate" label="创建时间：">
                                <el-date-picker class="w100i"
                                                v-model="inputForm.createDate" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd HH:mm:ss"
                                                type="datetimerange" clearable start-placeholder="开始时间"
                                                end-placeholder="结束时间">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item :label="item.forName ? item.forName : item.basicName + '：'" :prop="item.code"
                                          v-for="(item, index) in config"
                                          :key="index" v-if="item.dataWhere == 0">
                                <el-input v-model.trim="inputForm[item.code]" :maxlength="item.maxLength"
                                          :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                                          clearable
                                          v-if="item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length"></el-input>
                                <!--数值、浮点数-->
                                <el-input v-model.trim="inputForm[item.code]"
                                          :maxlength="item.archivesBasicTypeCode == 'integer'? '8':'11'"
                                          @input="getNumType(item)"
                                          :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                                          clearable
                                          v-if="item.archivesBasicTypeCode == 'integer' || item.archivesBasicTypeCode == 'double'"></el-input>
                                <el-select class="w100i" v-model="inputForm[item.code]"
                                           :multiple="item.archivesBasicMultiselectList[0].selectBy == 1" collapse-tags
                                           :placeholder="item.forName ? '请选择' + item.forName : '请选择' + item.basicName"
                                           clearable
                                           v-if="item.archivesBasicMultiselectList.length">
                                    <el-option v-for="(v,i) in item.archivesBasicMultiselectList" :key="i"
                                               :label="v.archivesBasicValue"
                                               :value="v.archivesBasicValue"></el-option>
                                </el-select>
                                <el-date-picker class="w100i"
                                                v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd HH:mm:ss"
                                                type="datetimerange" clearable start-placeholder="开始时间"
                                                end-placeholder="结束时间"
                                                v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd HH:mm:ss'">
                                </el-date-picker>
                                <el-date-picker class="w100i"
                                                v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd"
                                                type="daterange" clearable start-placeholder="开始时间"
                                                end-placeholder="结束时间"
                                                v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd'">
                                </el-date-picker>
                                <el-date-picker class="w100i"
                                                v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyyMMdd"
                                                type="daterange" clearable start-placeholder="开始时间"
                                                end-placeholder="结束时间"
                                                v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyyMMdd' || item.archivesBasicTypeCode == 'date' && !item.dataFormat">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                        <div class="flex_b_c">
                            <div>
                                <el-button type="primary" icon="el-icon-search" @click="getList(1)" size="small">查询
                                </el-button>
                                <el-button @click="reset()" size="small" icon="el-icon-refresh-right">重置</el-button>
                            </div>
                            <div>
                                <el-button type="primary" size="small" icon="el-icon-search" @click="advancedSearch()">
                                    高级检索
                                </el-button>
                                <el-button type="primary" size="small" icon="el-icon-plus"
                                           v-show="hasPermission('collect:list:add')"
                                           @click="addEdit(null, 'add')">收集
                                </el-button>
                                <el-button type="danger" size="small" icon="el-icon-delete"
                                           v-show="hasPermission('collect:list:delete')" @click="del()">批量删除
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white tableBgBox">
                        <div class="text_right">
                            <el-button size="small"
                                       v-show="hasPermission('collect:list:export')"
                                       @click="exportData()">
                                <i class="icon-piliangdaochu iconfont buIcon"/>
                                批量导出
                            </el-button>
                            <el-dropdown v-if="hasPermission('collect:list:import') && searchForm.archivesBasicDataId"
                                         class="m_r1 m_l1">
                                <el-button size="small" icon="el-icon-upload2">批量导入</el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>
                                        <el-upload class="i_block m_r1 m_l1"
                                                   ref="upload"
                                                   action="action" :show-file-list="false"
                                                   :http-request="getImportData"
                                                   :before-upload="beforeUpload">导入条目
                                        </el-upload>
                                    </el-dropdown-item>
                                    <el-dropdown-item class="text_center" @click.native="fileLinkageData()">导入文件
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-button size="small" icon="el-icon-download"
                                       v-show="hasPermission('collect:list:import')"
                                       @click="importTemplate()">导入模板下载
                            </el-button>
                        </div>
                        <el-table
                                :data="dataList"
                                v-loading="loading"
                                size="small"
                                @selection-change="selectionChangeHandle"
                                @sort-change="sortChange"
                                class="table" ref="multipleTable" :row-key='idKey'>
                            <el-table-column type="selection" :reserve-selection='true' fixed/>
                            <el-table-column prop="archivesBasicDataName" label="档案分类" min-width="120"
                                             show-overflow-tooltip/>
                            <el-table-column prop="archivesBasicDataType" label="档案类型" min-width="120"
                                             show-overflow-tooltip/>
                            <el-table-column prop="archivesDataName" label="题名" min-width="120" show-overflow-tooltip/>
                            <el-table-column prop="warehousingState" label="状态" min-width="120" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="quanzongNumber" label="全宗号" min-width="120"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="quanzongName" label="全宗名称" min-width="120"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="reference" label="档号" min-width="120"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column v-for="item in config" :key="item.archivesBasicId"
                                             :prop="item.code"
                                             :label="item.forName ? item.forName : item.basicName"
                                             v-if="item.dataSelect == 0"
                                             :sortable="item.archivesBasicTypeCode == 'date' || item.archivesBasicTypeCode == 'integer' ? 'custom' : false"
                                             show-overflow-tooltip min-width="120">
                                <template slot="header" slot-scope="scope">
                                    <el-tooltip class="item" effect="dark"
                                                :content="item.forName ? item.forName : item.basicName"
                                                placement="top-start">
                                        <span class="omit">{{item.forName ? item.forName : item.basicName}}</span>
                                    </el-tooltip>
                                </template>
                                <template slot-scope="scope">
                                    <span v-if="item.archivesBasicTypeCode == 'date'">{{metadataDateFormatValue(item, scope.row.archivesData[item.code])}}</span>
                                    <span v-else>{{ scope.row.archivesData[item.code]}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createBy" label="创建人" min-width="120"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="createDate" label="创建时间" min-width="120" sortable
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column fixed="right" width="180" label="操作">
                                <template slot-scope="scope">
                                    <el-button type="text" size="small" v-show="hasPermission('collect:list:view')"
                                               @click="addEdit(scope.row, 'view')">详情
                                    </el-button>
                                    <el-button type="text" size="small"
                                               v-show="hasPermission('collect:list:edit') && scope.row.borrowingState != '已借出' && scope.row.warehousingState == '在库' || scope.row.warehousingState == '残损'"
                                               @click="addEdit(scope.row, 'edit')">修改
                                    </el-button>
                                    <el-button type="text" size="small" v-show="hasPermission('collect:list:delete')"
                                               @click="del(scope.row.id)">删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                                @size-change="sizeChangeHandle"
                                @current-change="currentChangeHandle"
                                :current-page="searchForm.current"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="searchForm.size"
                                :total="total"
                                background
                                layout="total, sizes, prev, pager, next, jumper">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
        </el-row>
        <ListForm ref="listForm" @refreshDataList="getList()"></ListForm>
        <ExportData ref="exportData"></ExportData>
        <ImportData ref="importData" @refreshDataList="srhListData(1)"></ImportData>
        <FileLinkage ref="fileLinkage" @refreshDataList="srhListData(1)"></FileLinkage>
        <AdvancedSearch ref="advancedSearch" @getDataList="getSrhCondition"></AdvancedSearch>
    </div>
</template>

<script>
    import ListForm from './listForm'
    import ExportData from './exportData'
    import ImportData from './importData'
    import FileLinkage from './fileLinkage'
    import AdvancedSearch from '@/components/advancedSearch/advancedSearch'

    export default {
        components: {
            ListForm, ExportData, ImportData, AdvancedSearch, FileLinkage
        },
        data() {
            return {
                showList: true,
                treeData: [],
                treeLoading: false,
                defaultProps: {
                    children: 'children',
                    label: 'archivesBasicName'
                },
                searchForm: {
                    archivesBasicDataId: null,
                    current: 1,
                    size: 10,
                    whereList: [],
                    sortArchivesDataElasticsearch: [],
                    fieldOrList: [],
                    fieldWhereList: [
                        {
                            "archivesBasicTypeMethodCode": "=",
                            "archivesBasicTypeCode": "text",
                            "key": "archivesDataSource",
                            "value": "0",
                            "method": "and",
                        }
                    ],
                },
                advancedList: [],
                inputForm: {
                    keyword: '',
                    archivesBasicDataType: '',
                    warehousingState: '',
                    archivesDataName: '',
                    quanzongNumber: '',
                    quanzongName: '',
                    reference: '',
                    createBy: '',
                    createDate: [],
                },
                config: [],
                loading: false,
                dataList: [],
                total: 0,
                archivesBasicDataName: '',
                dataListSelections: [],
                idKey: 'id',
                userList: [],
            }
        },
        mounted() {
            this.searchTree()
            this.getUserList()
            this.selRecord()
        },
        methods: {
            // 查询树状数据
            searchTree() {
                this.treeLoading = true
                this.$axios(this.api.record.selectArchivesBasicData).then((res) => {
                    if (res.status) {
                        this.treeData = res.data
                        this.treeData.unshift({
                            archivesBasicName: '全部',
                            id: '',
                            children: []
                        })
                        this.$nextTick(() => {
                            this.$refs.myTree.setCurrentKey(this.treeData[0].id);
                        });
                    }
                    this.treeLoading = false
                })
            },
            // 选择档案分类
            selRecord(val) {
                this.advancedList = []
                this.searchForm.whereList = []
                this.searchForm.sortArchivesDataElasticsearch = []
                this.searchForm.fieldWhereList = this.searchForm.fieldWhereList.slice(0, 1)
                this.searchForm.archivesBasicDataId = null
                if (val && val.id) {
                    this.searchForm.archivesBasicDataId = val.id
                    this.archivesBasicDataName = val.archivesBasicName
                    this.$axios(this.api.record.basicdataGetById + '/' + val.id).then(data => {
                        if (data.status) {
                            this.inputForm = {
                                keyword: '',
                                archivesBasicDataType: '',
                                warehousingState: '',
                                archivesDataName: '',
                                quanzongNumber: '',
                                quanzongName: '',
                                reference: '',
                                createBy: '',
                                createDate: [],
                            }
                            this.config = data.data.archivesBasicDataGroup
                            this.config.map(item => {
                                if (item.dataWhere == 0) {
                                    this.$set(item, 'value', '')
                                    this.$set(this.inputForm, item.code, '')
                                }
                            })
                            this.getList(1)
                        }
                    })
                } else {
                    this.archivesBasicDataName = '全部'
                    this.getAllData()
                }
            },
            // 全部分类元数据字段
            getAllData() {
                this.$axios(this.api.record.getArchivesBasicByRequired).then((res) => {
                    if (res.status) {
                        this.config = res.data
                        this.config.map(item => {
                            if (item.dataWhere == 0) {
                                this.$set(item, 'value', '')
                                this.$set(this.inputForm, item.code, '')
                            }
                        })
                        this.srhListData(1)
                    }
                })
            },
            // 查询
            getList(type) {
                this.$refs.multipleTable.clearSort()
                this.searchForm.whereList = []
                this.searchForm.sortArchivesDataElasticsearch = []
                this.searchForm.fieldWhereList = this.searchForm.fieldWhereList.slice(0, 1)
                let keys = Object.keys(this.inputForm)
                let values = Object.values(this.inputForm)
                keys.map((key, index) => {
                    this.config.map(item => {
                        if (item.code == key && values[index]) {
                            if (item.archivesBasicTypeCode == 'text') {
                                // 多选遍历多选的值
                                if (Array.isArray(values[index])) {
                                    values[index].map(v => {
                                        this.searchForm.whereList.push({
                                            archivesBasicTypeMethodCode: "=",
                                            archivesBasicTypeCode: item.archivesBasicTypeCode,
                                            key: item.code,
                                            value: v
                                        })
                                    })
                                } else {
                                    this.searchForm.whereList.push({
                                        archivesBasicTypeMethodCode: "%",
                                        archivesBasicTypeCode: item.archivesBasicTypeCode,
                                        key: item.code,
                                        value: values[index]
                                    })
                                }
                            }
                            if (item.archivesBasicTypeCode == 'integer') {
                                this.searchForm.whereList.push({
                                    archivesBasicTypeMethodCode: "=",
                                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                                    key: item.code,
                                    value: values[index]
                                })
                            }
                            if (item.archivesBasicTypeCode == 'date') {
                                this.searchForm.whereList.push({
                                    archivesBasicTypeMethodCode: ">=",
                                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                                    key: item.code,
                                    value: values[index] ? values[index][0] : ''
                                })
                                this.searchForm.whereList.push({
                                    archivesBasicTypeMethodCode: "<=",
                                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                                    key: item.code,
                                    value: values[index] ? values[index][1] : ''
                                })
                            }
                        }
                    })
                })
                this.srhListData(type)
            },
            // 列表数据查询
            srhListData(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.dataListSelections = []
                this.$refs.multipleTable.clearSelection()
                this.loading = true
                if (this.inputForm.archivesBasicDataType) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "=",
                        archivesBasicTypeCode: "text",
                        key: "archivesBasicDataType",
                        value: this.inputForm.archivesBasicDataType,
                        "method": "and",
                    })
                }
                if (this.inputForm.warehousingState) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "=",
                        archivesBasicTypeCode: "text",
                        key: "warehousingState",
                        value: this.inputForm.warehousingState,
                        "method": "and",
                    })
                }
                if (this.inputForm.archivesDataName) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "%",
                        archivesBasicTypeCode: "text",
                        key: "archivesDataName",
                        value: this.inputForm.archivesDataName,
                        "method": "and",
                    })
                }
                if (this.inputForm.quanzongNumber) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "%",
                        archivesBasicTypeCode: "text",
                        key: "quanzongNumber",
                        value: this.inputForm.quanzongNumber,
                        "method": "and",
                    })
                }
                if (this.inputForm.quanzongName) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "%",
                        archivesBasicTypeCode: "text",
                        key: "quanzongName",
                        value: this.inputForm.quanzongName,
                        "method": "and",
                    })
                }
                if (this.inputForm.reference) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "%",
                        archivesBasicTypeCode: "text",
                        key: "reference",
                        value: this.inputForm.reference,
                        "method": "and",
                    })
                }
                if (this.inputForm.createBy) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "=",
                        archivesBasicTypeCode: "text",
                        key: "createBy",
                        value: this.inputForm.createBy,
                        "method": "and",
                    })
                }
                if (this.inputForm.createDate[0]) {
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: ">=",
                        archivesBasicTypeCode: "date",
                        key: "createDate",
                        value: this.inputForm.createDate[0],
                        "method": "and",
                    })
                    this.searchForm.fieldWhereList.push({
                        archivesBasicTypeMethodCode: "<=",
                        archivesBasicTypeCode: "date",
                        key: "createDate",
                        value: this.inputForm.createDate[1],
                        "method": "and",
                    })
                }
                this.searchForm.keyword = this.inputForm.keyword
                this.$axios(this.api.record.queryElasticsearchTemplatePage, this.searchForm, 'post').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.srhListData()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.dataList = []
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 列表排序
            sortChange(column) {
                if (column.prop == "createDate") {
                    return
                }
                this.searchForm.sortArchivesDataElasticsearch = [
                    {
                        key: column.prop,
                        sort: column.order == "descending" ? 'desc' : (column.order == "ascending" ? 'asc' : ''),
                    }
                ]
                this.srhListData()
            },
            // 高级检索
            advancedSearch() {
                this.$refs.advancedSearch.init(this.config, this.advancedList)
            },
            // 获取高级检索条件
            getSrhCondition(data) {
                if (data) {
                    this.advancedList = data.advancedList
                    this.searchForm.whereList = data.whereList
                    this.srhListData(1)
                }
            },
            // 新增
            addEdit(row, method) {
                if (method == 'add') {
                    this.$refs.listForm.init(row, method, this.searchForm.archivesBasicDataId)
                } else {
                    this.$refs.listForm.init(row, method, row.archivesBasicDataId)
                }
            },
            // 获取元数据类型设置输入正则
            getNumType(item) {
                let regular = /[^\d]/g
                if (item.archivesBasicTypeCode == "double") {
                    regular = /[^\d.]/g
                }
                this.$set(this.inputForm, item.code, this.inputForm[item.code].replace(regular, ''))
            },
            beforeUpload(file) { // 上传文件之前钩子
                const type = file.name.split('.')[1]
                if (type !== 'xls' && type !== 'xlsx') {
                    this.$message({type: 'error', message: '只支持xls、xlsx文件格式！'})
                    return false
                }
            },
            // 导入文件
            fileLinkageData() {
                this.$refs.fileLinkage.init()
            },
            // 导入
            getImportData(param) {
                this.$refs.importData.init(param, this.searchForm.archivesBasicDataId, '0')
            },
            // 导入模板下载
            importTemplate() {
                this.exportExcel(this.api.record.exportModel, {
                    archivesBasicDataId: this.searchForm.archivesBasicDataId
                }, this.archivesBasicDataName)
            },
            // 导出
            exportData() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let idList = this.dataListSelections.map(item => {
                    return item.id
                })
                this.$refs.exportData.init(idList, this.config, this.searchForm.archivesBasicDataId, this.archivesBasicDataName)
            },
            // 删除
            del(id) {
                let ids = [id];
                if (!id && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if (!id) {
                    ids = this.dataListSelections.map(item => {
                        return item.id
                    })
                }
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.record.removeById, {
                        archivesBasicDataId: this.searchForm.archivesBasicDataId,
                        ids: ids
                    }, 'post').then(data => {
                        if (data.status) {
                            this.$message.success('删除成功')
                            this.srhListData()
                        } else {
                            this.$message.error('删除失败')
                        }
                        this.loading = false
                    })
                })
            },
            // 获取用户列表
            getUserList() {
                this.$axios(this.api.auth.sysuserQueryAllList, {
                    current: 1,
                    size: 9999,
                    orderBy: '',
                    name: '',
                    loginName: '',
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.userList = data.data.records
                    }
                })
            },
            //重置
            reset() {
                this.$refs.inputForm.resetFields();
                this.getList(1)
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.srhListData()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.srhListData()
            },
        }
    }
</script>

<style scoped>

</style>
